import * as types from '../constants';

const initialState = {
  phoneNumber: '',
  timer: '',
  timerIntervalId: '',
  isStartTimer: false,
  isSkipBookingAuth: false,
  // isVisibleLocationServiceInfo: true,
  // isVisibleKeyIssueInfo: true,
};

const UserConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case types.SET_TIMER:
      return {
        ...state,
        timer: action.payload,
      };
    case types.SET_TIMER_INTERVER_ID:
      return {
        ...state,
        timerIntervalId: action.payload,
      };
    case types.SET_IS_START_TIMER:
      return {
        ...state,
        isStartTimer: action.payload,
      };
    case types.SET_SKIP_BOOKING_AUTH:
      return {
        ...state,
        isSkipBookingAuth: action.payload,
      };
    // case types.SET_IS_VISIBLE_LOCATION_SERVICE_INFO:
    //   return {
    //     ...state,
    //     isVisibleLocationServiceInfo: action.payload,
    //   };
    // case types.SET_IS_VISIBLE_KEY_ISSUE_INFO:
    //   return {
    //     ...state,
    //     isVisibleKeyIssueInfo: action.payload,
    //   };
    default:
      return state;
  }
};

export default UserConfigReducer;