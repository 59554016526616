import axios from 'axios';
// import _ from 'lodash';

// config
const request = axios.create({
  baseURL: process.env.REACT_APP_IMGATE_BACKEND_BASEURL,
});

const headers = (header) => {
  return {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('api_key')}`,
      ...header && header,
    }
  };
};

// const querystring = (query) => {
//   return {
//     params: query
//   };
// };

// const removeNullInObject = (obj) => {
//   return _.pickBy(obj, function (value, key) {
//     return !_.isEmpty(value);
//   });
// }

export const requestVerificationCode = ({
  phoneNumber:phone_number,
  expiredAt:expired_at,
  isTestMode:is_test_mode,
}) => {
  return request.post('/multifamily/users/yonginforest/verification_code', {
    phone_number,
    expired_at,
    is_test_mode,
  });
}

export const verifyVerificationCode = ({
  phoneNumber:phone_number,
  verificationCode:verification_code,
  testHotelId:test_hotel_id,
  testBookings:test_bookings,
}) => {
  return request.post('/multifamily/users/yonginforest/verification_token', {
    phone_number,
    verification_code,
    test_hotel_id,
    test_bookings,
  });
}

export const listBooking = () => {
  return request.get('/multifamily/users/yonginforest/bookings', {
    ...headers(),
  });
}

export const issuePincode = ({
  bookingId:booking_id,
}) => {
  return request.post(`/multifamily/users/yonginforest/bookings/${booking_id}/pincodes`,
    {},
    headers(),
  );
}

export const issueMobilekey = ({
  bookingId:booking_id,
}) => {
  return request.post(`/multifamily/users/yonginforest/bookings/${booking_id}/mobilekeys`,
    {},
    headers(),
  );
}