import React, { Fragment, useState } from 'react';
import { Affix, Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

const ButtonRefresh = ({
  refresh,
}) => {
  const [ isSpin, setIsSpin ] = useState(false);

  const startSpin = () => {
    setIsSpin(true);
  };

  const finishSpin = () => {
    setIsSpin(false);
  };

  const onRefresh = () => {
    startSpin();
    refresh();
    setTimeout(() => finishSpin(), 500);
  };

  return (
    <Fragment>
      <Affix style={{position:'fixed', right:'20px', bottom:'60px'}}>
        <Button type='primary' size='large' className='fix-refresh-button' onClick={onRefresh}>
          <SyncOutlined spin={isSpin}/>
        </Button>
      </Affix>
    </Fragment>
  );
};

export default ButtonRefresh;