import React, { Fragment } from 'react';
import { Form, Button, Input } from 'antd';
import { PhoneOutlined, NumberOutlined } from '@ant-design/icons';
import { alertFail } from '../Alert';

const BookingAuth = ({
  phoneNumber,
  verificationCode,
  timer,
  timerIntervalId,
  isStartTimer,
  changePhoneNumber,
  changeVerificationCode,
  setIsStartTimer,
  requestVerificationCode,
  verifyVerificationCode,
  history,
}) => {
  const onRequestVerificationCode = async () => {
    try {
      if (!phoneNumber) alertFail('전화번호를 입력해주세요.', 3);
      else if (!validatePhoneNumber(phoneNumber)) alertFail('전화번호는 숫자만 입력해주세요.', 3);
      else await requestVerificationCode();
    } catch (error) {
      if (error.response) {
        if (error.response.data.code === 400) alertFail('전화번호가 올바르지 않습니다.', 3);
        else alertFail(error.response.data.message, 3);
      }
      else alertFail(error.message, 3);
    }
  };

  const onVerifyVerificationCode = async () => {
    try {
      if (!verificationCode) alertFail('인증번호를 입력해주세요.', 3);
      else {
        await verifyVerificationCode();
        history.replace('/booking/list');
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.code === 400) alertFail('전화번호가 올바르지 않습니다.', 3);
        else if (error.response.data.code === 401) alertFail('인증번호가 틀렸습니다.', 3);
        else if (error.response.data.code === 404) {
          clearInterval(timerIntervalId);
          setIsStartTimer(false);
          changeVerificationCode('');
          alertFail('예약이 없습니다.', 3);
        }
        else if (error.response.data.code === 422) alertFail('객실을 찾을 수 없습니다.', 3);
        else alertFail(error.response.data.message, 3);
      }
      else alertFail(error.message, 3);
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^[0-9]*$/;
    return regex.test(phoneNumber);
  };

  return (
    <Fragment>
      <p className='ta-center m-10 mt-30 pt-10 fs-18 bold c-white'>예약 확인 하기</p>
      <p className='m-10 ws-pl wb-ka fs-16 c-white'>
        객실 예약에 입력하셨던 전화번호와 인증번호를 입력 하시면 예약 확인을 하실 수 있습니다.
      </p>
      <Form fields={[{name: ['verificationCode'], value: verificationCode}]}>
        <Form.Item name='phoneNumber' rules={[{required: true, message: '전화번호를 입력해주세요'}]}>
          <Input
            className='mt-20 basic-input'
            type='tel'
            size='large'
            maxLength={11}
            allowClear
            prefix={<PhoneOutlined className='c-lightgray fs-18'/>}
            placeholder='전화번호 (숫자만 입력해주세요.)'
            value={phoneNumber}
            disabled={isStartTimer}
            onChange={(e) => changePhoneNumber(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type='primary' size='large' className='basic-button light-green' disabled={isStartTimer} onClick={onRequestVerificationCode}>
            인증번호 요청
          </Button>
        </Form.Item>
        <div hidden={!isStartTimer}>
          <Form.Item name='verificationCode' rules={[{required: true, message: '인증번호를 입력해주세요'}]}>
            <Input
              className='mt-20 basic-input has-suffix'
              type='number'
              inputMode='numeric'
              size='large'
              maxLength={6}
              allowClear
              prefix={<NumberOutlined className='c-lightgray fs-18'/>}
              suffix={timer}
              placeholder='인증번호'
              value={verificationCode}
              onChange={(e) => changeVerificationCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type='primary' size='large' className='basic-button light-green' onClick={onVerifyVerificationCode}>
              예약조회
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Fragment>
  );
};

export default BookingAuth;