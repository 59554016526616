import Main from '../components/views/Main';
import BookingAuth from '../containers/BookingAuthContainer';
import BookingList from '../containers/BookingListContainer';

const mainRoutes = {
  path: '/',
  name: 'Main',
  component: Main,
};

const bookingAuthRoutes = {
  path: '/booking/auth',
  name: 'Booking Auth',
  component: BookingAuth,
};

const bookingListRoutes = {
  path: '/booking/list',
  name: 'Booking List',
  component: BookingList,
};

export const routesList = [
  mainRoutes,
  bookingAuthRoutes,
  bookingListRoutes,
];