import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import _ from 'lodash';
import MainLayout from '../components/layouts/MainLayout';
import BookingList from '../components/views/BookingList';
import { alertFail } from '../components/Alert';
import * as api from '../api';
import { bookingListAction, userConfigAction } from '../stores/actions';

const BookingListContainer = ({
  history,
}) => {
  // const [ isOpenModalLocationService, setIsModalLocationServiceVisible ] = useState(false);
  const [ isOpenModalKeyIssueInfo, setIsOpenModalKeyIssueInfo ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ selectedBooking, setSelectedBooking ] = useState({});
  const { bookingListItems } = useSelector(state => state.bookingList);
  // const { isVisibleLocationServiceInfo, isVisibleKeyIssueInfo } = useSelector(state => state.userConfig);
  // const isTestMode = process.env.REACT_APP_ENV === 'production' ? false : true;
  const dispatch = useDispatch();

  // const openModalLocationService = useCallback(() => {
  //   setIsModalLocationServiceVisible(true);
  // }, []);

  // const closeModalLocationService = useCallback(() => {
  //   setIsModalLocationServiceVisible(false);
  // }, []);

  const openModalKeyIssueInfo = useCallback(() => {
    setIsOpenModalKeyIssueInfo(true);
  }, []);

  const closeModalKeyIssueInfo = useCallback(() => {
    setIsOpenModalKeyIssueInfo(false);
  }, []);

  const skipBookingAuth = useCallback(() => {
    dispatch(userConfigAction.setIsSkipBookingAuth(true));
  }, [dispatch]);

  const newBookingAuth = useCallback(() => {
    dispatch(userConfigAction.setIsSkipBookingAuth(false));
  }, [dispatch]);

  // const verifyCurrentLocation = useCallback(() => {
  //   return new Promise(function(resolve, reject) {
  //     const yonginForestLat = 37.314108;
  //     const yonginForestLng = 127.269504;
  //     const centerRadiusOfYonginForest = 1000; // 용인 휴양림 내부 중심으로 부터 반경: 1000m
  //     const option = {
  //       enableHighAccuracy: false,
  //       maximumAge: 5000,
  //       timeout: 3000,
  //     };
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(function (position) {
  //         const currentLat = position.coords.latitude;
  //         const currentLng = position.coords.longitude;
  //         const currentDistance = getDistanceFromCurrentLocationToDestination(currentLat, currentLng, yonginForestLat, yonginForestLng);
  //         if (currentDistance - centerRadiusOfYonginForest < 0 || isTestMode) resolve();
  //         else reject({code: 0, message: '현재 사용자 위치는 용인자연휴양림 내부가 아닙니다.'});
  //       }, (error) => {
  //         const formatErrorMessage = {
  //           0: '알 수 없는 에러 입니다.',
  //           1: '위치 서비스가 꺼져 있습니다. 위치 서비스를 켜 주세요.',
  //           2: '위치를 찾을 수 없습니다.',
  //           3: '요청 응답 시간이 초과 되었습니다. 재요청 해주세요.'
  //         };
  //         reject({code: 0, message: formatErrorMessage[error.code] || error.message});
  //       }, (option));
  //     } else reject({code: 0, message: '이 브라우저에서는 Geolocation이 지원되지 않습니다.'});
  //   });
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // function getDistanceFromCurrentLocationToDestination(currentLat, currentLng, destinationLat, destinationLng) {
  //   var R = 6371; // Radius of the earth in km
  //   var dLat = toRadians(destinationLat - currentLat);
  //   var dLng = toRadians(destinationLng - currentLng);
  //   var a =
  //     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  //     Math.cos(toRadians(currentLat)) * Math.cos(toRadians(destinationLat)) *
  //     Math.sin(dLng / 2) * Math.sin(dLng / 2);
  //   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //   var d = R * c * 1000; // Distance in m
  //   return d;
  // }

  // function toRadians(deg) {
  //   return deg * (Math.PI / 180);
  // }

  const listBooking = useCallback(async () => {
    try {
      const { data: bookings } = await api.listBooking();
      dispatch(bookingListAction.setBookingList(bookings.items));
      skipBookingAuth();
    } catch (error) {
      if (error.response) alertFail(error.response.data.message, 3);
      else alertFail(error.message, 3);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, skipBookingAuth]);

  const issueKey = useCallback(async () => {
    try {
      if (!_.isEmpty(selectedBooking) && !selectedBooking.pincodes.length) await api.issuePincode({bookingId: selectedBooking.id});
      // if (!_.isEmpty(selectedBooking) && !selectedBooking.mobilekeys.length) await api.issueMobilekey({bookingId: selectedBooking.id});
    } catch (error) {
      throw error;
    }
  }, [selectedBooking]);

  useEffect(() => {
    if (sessionStorage.getItem('api_key')) listBooking();
    else setIsLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Spin spinning={isLoading} tip="Loading...">
        <MainLayout
          history={history}
          ContentBody={(
              <BookingList
                // isOpenModalLocationService={isOpenModalLocationService}
                // openModalLocationService={openModalLocationService}
                // closeModalLocationService={closeModalLocationService}
                isOpenModalKeyIssueInfo={isOpenModalKeyIssueInfo}
                openModalKeyIssueInfo={openModalKeyIssueInfo}
                closeModalKeyIssueInfo={closeModalKeyIssueInfo}
                // isVisibleLocationServiceInfo={isVisibleLocationServiceInfo}
                // isVisibleKeyIssueInfo={isVisibleKeyIssueInfo}
                bookingListItems={bookingListItems}
                // verifyCurrentLocation={verifyCurrentLocation}
                listBooking={listBooking}
                issueKey={issueKey}
                selectedBooking={selectedBooking}
                setSelectedBooking={setSelectedBooking}
                newBookingAuth={newBookingAuth}
                history={history}
              />
          )}
        />
      </Spin>
    </Fragment>
  );
};

export default BookingListContainer;