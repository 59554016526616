import React, { Fragment } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Modal } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { alertSuccess, alertFail } from './Alert';
// import { userConfigAction } from '../stores/actions';

const ModalKeyIssueInfo = ({
  isOpen,
  bookingItem,
  onClose,
  issueKey,
  refresh,
}) => {
  // const [ isChecked, setIsChecked ] = useState(false);
  // const { isVisibleKeyIssueInfo: visible } = useSelector(state => state.userConfig);
  // const dispatch = useDispatch();

  // const convertIsChecked = () => {
  //   setIsChecked(!isChecked);
  // };

  const formatCheckinTime = (checkinAt) => {
    return `${moment(checkinAt).format('A') === 'AM' ? '오전' : '오후'} ${moment(checkinAt).format('h')}시`;
  };

  const confirm = async () => {
    try {
      await issueKey();
      alertSuccess('키발급이 완료 되었습니다.', 3);
      await refresh();
    } catch (error) {
      if (error.response) alertFail(error.response.data.message, 3);
      else alertFail(error.message, 3);
    } finally {
      // dispatch(userConfigAction.setIsVisibleKeyIssueInfo(!isChecked));
      onClose();
    }
  };

  return (
    <Fragment>
      <Modal
        visible={isOpen}
        closable={false}
        centered
        footer={(
          <Button type='primary' size='large' className='basic-button dark-green' onClick={confirm}>
            확인
          </Button>
        )}
        maskClosable={false}
      >
        <p className='m-10 pt-20 ta-center fs-18 bold'>키 사용 안내</p>
        <div className='mt-30 mb-30 ta-center'>
          <HistoryOutlined className='modal-location-image'/>
        </div>
        <p className='m-10 ta-center ws-pl wb-ka fs-16'>
          발급된 키는<br/> {formatCheckinTime(bookingItem.checkin_at)}부터 사용 가능합니다.
        </p>
        {/* <div className='mt-20 w-100 ta-center'>
          <Checkbox onChange={convertIsChecked}>다시 보지 않기</Checkbox>
        </div> */}
      </Modal>
    </Fragment>
  );
};

export default ModalKeyIssueInfo;