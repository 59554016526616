import React, { Fragment } from 'react';
import { Form, Button} from 'antd';
import moment from 'moment';
import { FileUnknownOutlined } from '@ant-design/icons';
// import ModalLocationService from '../ModalLocationService';
import ModalKeyIssueInfo from '../ModalKeyIssueInfo';
// import TooltipMobilekeyInfo from '../TooltipMobilekeyInfo';
import ButtonRefresh from '../ButtonRefresh';
// import { alertSuccess, alertFail } from '../Alert';

const BookingList = ({
  // isOpenModalLocationService,
  // openModalLocationService,
  // closeModalLocationService,
  isOpenModalKeyIssueInfo,
  openModalKeyIssueInfo,
  closeModalKeyIssueInfo,
  // isVisibleLocationServiceInfo,
  // isVisibleKeyIssueInfo,
  bookingListItems,
  listBooking,
  // verifyCurrentLocation,
  issueKey,
  selectedBooking,
  setSelectedBooking,
  newBookingAuth,
  history,
}) => {
  const refresh = async () => {
    await listBooking();
  };

  const goHomePage = () => {
    history.push('/');
  };

  const goBookingAuthPage = () => {
    newBookingAuth();
    history.push('/booking/auth');
  };

  const onIssueKey = (booking) => {
    setSelectedBooking(booking);
    openModalKeyIssueInfo();
    // try {
    //   // if (isVisibleLocationServiceInfo) openModalLocationService();
    //   // else {
    //   //   await issueKey();
    //   //   alertSuccess('키발급이 완료 되었습니다.', 3);
    //   //   await listBooking();
    //   // }
    //   // else {
    //   //   await verifyCurrentLocation();
    //   // }
    // } catch (error) {
    //   if (error.response) alertFail(error.response.data.message, 3);
    //   else alertFail(error.message, 3);
    // }
  };

  const setBookingPeriod = (checkinAt, checkoutAt) => {
    return `${moment(checkinAt).format('YYYY.M.D')} ~ ${moment(checkoutAt).format('YYYY.M.D')}`;
  };

  const pincodeFormatter = (pincodes) => {
    // const pickedPincodes = pincodes.map(pincode => pincode.pincode);
    // return _.compact(pickedPincodes).join(', ');
    return `${pincodes[0].pincode} + *`;
  };

  // const mobilekeyFormatter = (mobilekeys) => {
  //   // const pickedMobilekeys = mobilekeys.map(mobilekey => mobilekey.exchangekey || '사용중');
  //   // return _.compact(pickedMobilekeys).join(', ');
  //   return mobilekeys[0].exchangekey || '사용중';
  // };

  return (
    <Fragment>
      <p className='m-10 pt-30 ta-center fs-18 bold c-white'>예약 목록</p>
      {bookingListItems.length > 0 ? (
        <Fragment>
          {bookingListItems.map((item, index) => (
            <div className='form-card-wrap' key={index}>
              <Form className='card'>
                <Form.Item className='mb-10 bb-dot-green' label='객실정보' colon={false}>
                  <p className='ml-30 mb-0 fs-16 bold'>{item.room.name}</p>
                </Form.Item>
                <Form.Item className='mb-10 bb-dot-green' label='출입번호' colon={false}>
                  <p className='ml-30 mb-0 fs-16 bold'>{item.pincodes.length ? pincodeFormatter(item.pincodes) : '미발급'}</p>
                </Form.Item>
                {/* <Form.Item className='mb-10 bb-dot-green' label='모바일키' colon={false} tooltip={{
                  title: <TooltipMobilekeyInfo/>,
                  className: 'c-blue',
                  placement: 'bottomLeft',
                  color: '#ffffff',
                  trigger: ['hover', 'click'],
                }}>
                  <p className='ml-12 mb-0 fs-16 bold'>{item.mobilekeys.length ? mobilekeyFormatter(item.mobilekeys) : '미발급'}</p>
                </Form.Item> */}
                <Form.Item className='mb-10 bb-dot-green' label='투숙기간' colon={false}>
                  <p className='ml-30 mb-0 fs-16 bold'>{setBookingPeriod(item.checkin_at, item.checkout_at)}</p>
                </Form.Item>
                <Form.Item hidden={item.pincodes.length}>
                  <Button type='primary' size='large' className='basic-button dark-green' onClick={() => onIssueKey(item)}>
                    객실 출입키 요청하기
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ))}
        </Fragment>
      ) : (
        <Fragment>
          <div className='no-list'>
            <FileUnknownOutlined />
          </div>
          <p className='m-10 mb-30 ta-center fs-16 c-white ws-pl wb-ka'>예약 내역을 찾을 수가 없습니다.<br/>예약 날짜 확인 후, 다시 조회 해주세요.</p>
          <Button type='primary' size='large' className='mt-10 basic-button light-green' onClick={goBookingAuthPage}>새 조회하기</Button>
        </Fragment>
      )}
      <Button type='primary' size='large' className='mt-10 basic-button light-green' onClick={goHomePage}>홈으로</Button>
      <ButtonRefresh refresh={refresh}/>
      {/* <ModalLocationService
        isOpen={isOpenModalLocationService}
        onClose={closeModalLocationService}
        verifyCurrentLocation={verifyCurrentLocation}
        openModalKeyIssueInfo={openModalKeyIssueInfo}
        issueKey={issueKey}
        refresh={listBooking}
      /> */}
      <ModalKeyIssueInfo
        isOpen={isOpenModalKeyIssueInfo}
        bookingItem={selectedBooking}
        onClose={closeModalKeyIssueInfo}
        issueKey={issueKey}
        refresh={listBooking}
      />
    </Fragment>
  );
};

export default BookingList;