import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MainLayout from '../components/layouts/MainLayout';
import BookingAuth from '../components/views/BookingAuth';
import { userConfigAction } from '../stores/actions';
import * as api from '../api';

const BookingAuthContainer = ({
  history,
}) => {
  const dispatch = useDispatch();
  const { phoneNumber, timer, timerIntervalId, isStartTimer, isSkipBookingAuth } = useSelector(state => state.userConfig);
  const [ verificationCode, setVerificationCode ] = useState('');
  const countryNumber = '82';
  const isTestMode = process.env.REACT_APP_ENV === 'production' ? false : true;

  const changePhoneNumber = useCallback((phoneNumber) => {
    dispatch(userConfigAction.setPhoneNumber(phoneNumber));
  }, [dispatch]);

  const changeVerificationCode = useCallback((verificationCode) => {
    setVerificationCode(verificationCode);
  }, []);

  const setIsStartTimer = useCallback((isStartTimer) => {
    dispatch(userConfigAction.setIsStartTimer(isStartTimer));
  }, [dispatch]);

  const getExpireDate = () => {
    let now = new Date();
    let expireDate = new Date();
    expireDate.setMinutes(now.getMinutes() + 3);
    return expireDate;
  };

  const runTimer = useCallback((verificationTime) => {
    dispatch(userConfigAction.setTimer(('03:00')));
    const repeat = setInterval(() => {
      const now = new Date();
      const expireDate = new Date(verificationTime);
      const gap = expireDate.getTime() - now.getTime();
      let sec_gap = Math.floor(gap/1000) % 60;
      sec_gap = sec_gap < 10 ? '0' + sec_gap : sec_gap;
      const min_gap = Math.floor(gap/(1000*60));
      dispatch(userConfigAction.setTimerIntervalId((repeat)));
      dispatch(userConfigAction.setTimer(('0' + min_gap + ':' + sec_gap)));
      if(gap <= 0) {
        clearInterval(repeat);
        dispatch(userConfigAction.setTimerIntervalId(('')));
        dispatch(userConfigAction.setTimer(('')));
        changeVerificationCode('');
        setIsStartTimer(false);
      }
    }, 1000);
  }, [changeVerificationCode, dispatch, setIsStartTimer]);

  const requestVerificationCode = useCallback(async () => {
    try {
      const reqPhoneNumber = countryNumber + Number.parseInt(phoneNumber);
      const res = await api.requestVerificationCode({
        phoneNumber: reqPhoneNumber,
        expiredAt: getExpireDate(),
        isTestMode,
      });
      // setTimeout(() => setIsStartTimer(false), 3 * 60 * 1000);
      setIsStartTimer(true);
      if (isTestMode) changeVerificationCode(res.data.verification_code);
      runTimer(res.data.expired_at);
    } catch (error) {
      throw error;
    }
  }, [changeVerificationCode, isTestMode, phoneNumber, runTimer, setIsStartTimer]);

  const verifyVerificationCode = useCallback(async () => {
    try {
      const reqPhoneNumber = countryNumber + Number.parseInt(phoneNumber);
      const res = await api.verifyVerificationCode({
        phoneNumber: reqPhoneNumber,
        verificationCode,
        ...isTestMode && {
          testHotelId: 'ee61285bd1c641b9a75e6d21afc9d9384375e82f',
          testBookings: [{
            room_name: '가마골1',
            checkin_date: '20210624',
            checkout_date: '20210625',
          }],
        },
      });
      sessionStorage.setItem('api_key', res.data.api_key);
    } catch (error) {
      throw error;
    }
  }, [isTestMode, phoneNumber, verificationCode]);

  useEffect(() => {
    clearInterval(timerIntervalId);
    dispatch(userConfigAction.setTimerIntervalId(('')));
    dispatch(userConfigAction.setTimer(('')));
    setIsStartTimer(false);
    changeVerificationCode('');
    if (isSkipBookingAuth) history.replace('/booking/list');
    else changePhoneNumber('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, isSkipBookingAuth]);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <BookingAuth
            phoneNumber={phoneNumber}
            verificationCode={verificationCode}
            timer={timer}
            timerIntervalId={timerIntervalId}
            isStartTimer={isStartTimer}
            changePhoneNumber={changePhoneNumber}
            changeVerificationCode={changeVerificationCode}
            setIsStartTimer={setIsStartTimer}
            requestVerificationCode={requestVerificationCode}
            verifyVerificationCode={verifyVerificationCode}
            history={history}
          />
        )}
      />
    </Fragment>
  );
};

export default BookingAuthContainer;