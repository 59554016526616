import { combineReducers } from 'redux';
import bookingList from './BookingListReducer';
import userConfig from './UserConfigReducer';

const rootReducer = combineReducers({
  bookingList,
  userConfig,
});

export default rootReducer;
