import * as types from '../constants';

export const setPhoneNumber = (phoneNumber) => ({
  type: types.SET_PHONE_NUMBER,
  payload: phoneNumber,
});

export const setTimer = timer => ({
  type: types.SET_TIMER,
  payload: timer
});

export const setTimerIntervalId = timerIntervalId => ({
  type: types.SET_TIMER_INTERVER_ID,
  payload: timerIntervalId
});

export const setIsStartTimer = isStartTimer => ({
  type: types.SET_IS_START_TIMER,
  payload: isStartTimer
});

export const setIsSkipBookingAuth = (isSkipBookingAuth) => ({
  type: types.SET_SKIP_BOOKING_AUTH,
  payload: isSkipBookingAuth,
});

// export const setIsVisibleLocationServiceInfo = (isVisibleLocationServiceInfo) => ({
//   type: types.SET_IS_VISIBLE_LOCATION_SERVICE_INFO,
//   payload: isVisibleLocationServiceInfo,
// });

// export const setIsVisibleKeyIssueInfo = (isVisibleKeyIssueInfo) => ({
//   type: types.SET_IS_VISIBLE_KEY_ISSUE_INFO,
//   payload: isVisibleKeyIssueInfo,
// });
